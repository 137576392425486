.popup > :global(.mapboxgl-popup-content) {
  padding: 4px 8px 2px;
}
.popup.dark > :global(.mapboxgl-popup-tip) {
  border-top-color: black !important;
}
.popup.dark > :global(.mapboxgl-popup-content) {
  background-color: black;
  color: white;
}
