.vessel.selected::before {
  content: '';
  border-radius: 100%;
  border: solid 1.5px white;
  position: absolute;
  top: calc(-50% - 1.5px);
  left: calc(-50% - 1.5px);
  width: calc(200% + 3px);
  height: calc(200% + 3px);
  z-index: -1;
  animation: pulse-ring 1.2s cubic-bezier(0.2, 0, 0.9, 1) infinite;
}

.vessel.light_selected::before {
  border: solid 1.5px black;
}

.vessel.light::after {
  background-color: gray;
}

.vessel::after {
  content: '';
  background-color: black;
  border-radius: 100%;
  width: 50%;
  height: 50%;
  position: absolute;
  top: 25%;
  left: 25%;
  z-index: -2;
  opacity: 0;
  transition: 0.5s all;
}

.vessel.selected::after {
  opacity: 1;
  width: 200%;
  height: 200%;
  top: -50%;
  left: -50%;
}

.vessel:hover::after {
  opacity: 0.5;
  width: 200%;
  height: 200%;
  top: -50%;
  left: -50%;
}

@keyframes pulse-ring {
  0% {
    transform: scale(1.5);
    opacity: 0.2;
  }
  20% {
    transform: scale(1);
    opacity: 1;
  }
  25% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(1.5);
  }
}
